interface LinkProps {
  prispek: string;
  spektra: string;
  prisma: string;
  surgeon: string;
  ceramic: string;
  precise: string;
  liquor: string;
  rj: string;
  map: string;
}

const Links: LinkProps = {
  prispek: 'https://prispek.co.za/',
  spektra: 'https://prispek.co.za/',
  prisma: 'https://prispek.co.za/',
  surgeon: 'http://www.drgreenapple.co.za/',
  ceramic: '/',
  precise: '/',
  liquor: 'https://liquorcity.co.za/liquor-city-alberts',
  rj: 'https://www.pretoriaperiodontist.co.za/index.php',
  map:
    'https://www.google.com/maps/place/406+Braam+Pretorius+St,+Magalieskruin,+Pretoria,+0150/@-25.68666,28.2301063,17z/data=!3m1!4b1!4m8!1m2!2m1!1skruin+business+centre+406+braam+pretorius+street+magalieskruin+0159!3m4!1s0x1ebfdf1ed8c8775d:0xb06c68a05c2eafee!8m2!3d-25.68666!4d28.232295'
};

export default Links;
