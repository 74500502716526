import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

export interface NavLinkProps extends ButtonProps {
  link: 'Home' | 'About' | 'Businesses' | 'Centre Management';
  page: boolean;
  drawer?: boolean;
}

const NavLinks = ({ link, page, drawer, ...other }: NavLinkProps) => (
  <Button
    {...other}
    style={{ color: page ? '#ffcf30' : drawer ? 'black' : 'white' }}
  >
    {link}
  </Button>
);

export default NavLinks;
