interface ThemeProps {
  primary: string;
  secondary: string;
}

const Theme: ThemeProps = {
  primary: '#056436',
  secondary: '#000066'
};

export default Theme;
