import Links from '../../Config/Constants/Links';

const About = () => (
  <div className='about'>
    <div className='about__card'>
      <h1 className='about__card__title'>History of Kruin Sakesentrum</h1>
      <p className='about__card__history'>
        Kruin Business Centre is a Community Centre which provides services to
        the local community and even further. Essential dental services are
        provided by{' '}
        <a
          href={Links.rj}
          target='_blank'
          rel='noopener noreferrer'
          className='about__card__history__link'
        >
          DR JOHN MUIRE
        </a>{' '}
        and{' '}
        <a
          href={Links.surgeon}
          target='_blank'
          rel='noopener noreferrer'
          className='about__card__history__link'
        >
          DR RONEL PODDE
        </a>
        , with dental laboratory support provided by{' '}
        {/* <a
          href={Links.precise}
          target='_blank'
          rel='noopener noreferrer'
          className='about__card__history__link'
        > */}
        <span className='about__card__history__link'>
          PRECISE DENTAL LABORATORIES
        </span>
        .{' '}
        <a
          href={Links.liquor}
          target='_blank'
          rel='noopener noreferrer'
          className='about__card__history__link'
        >
          ALBERTS LIQUOR CITY
        </a>{' '}
        has been serving the communities since the early 1990’s as well as{' '}
        <a
          href={Links.prispek}
          target='_blank'
          rel='noopener noreferrer'
          className='about__card__history__link'
        >
          PRISPEK CONSULTING
        </a>{' '}
        providing valuable financial services, in collaboration with{' '}
        <a
          href={Links.prisma}
          target='_blank'
          rel='noopener noreferrer'
          className='about__card__history__link'
        >
          PRISMA ACCOUNTANTS
        </a>{' '}
        and{' '}
        <a
          href={Links.spektra}
          target='_blank'
          rel='noopener noreferrer'
          className='about__card__history__link'
        >
          SPEKTRA BROKERS
        </a>
        .
      </p>
    </div>
  </div>
);

export default About;
