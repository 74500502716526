import React, { createContext, useContext, useRef } from 'react';

interface RefProps {
  topRef: any;
  aboutRef: any;
  businessesRef: any;
  centreRef: any;
}

const initialState = {
  topRef: null,
  aboutRef: null,
  businessesRef: null,
  centreRef: null
};

export const RefsContext = createContext<RefProps>(initialState);

const RefContextProvider = ({ children }: any) => {
  const topRef = useRef<HTMLElement>(null);
  const aboutRef = useRef<HTMLElement>(null);
  const businessesRef = useRef<HTMLElement>(null);
  const centreRef = useRef<HTMLElement>(null);

  return (
    <RefsContext.Provider
      value={{
        topRef,
        aboutRef,
        businessesRef,
        centreRef
      }}
    >
      {children}
    </RefsContext.Provider>
  );
};

export const useMyRefs = () => useContext(RefsContext);

export default RefContextProvider;
