import Prispek from '../../Assets/images/PRISPEK.png';
import Spektra from '../../Assets/images/SPEKTRA.png';
import Prisma from '../../Assets/images/PRISMA.jpg';
import Surgeon from '../../Assets/images/dental-surgeon.png';
import Ceramic from '../../Assets/images/Ceramic-Logo.png';
import Precise from '../../Assets/images/Precise-Logo.png';
import Liquor from '../../Assets/images/liquor-city.jpg';
import RJ from '../../Assets/images/R&J Dental Board Logo.png';
import Links from '../../Config/Constants/Links';

const Businesses = () => (
  <div className='businesses'>
    <h1 className='businesses__title'>Our Businesses</h1>
    <div className='businesses__grid'>
      <a
        href={Links.prispek}
        target='_blank'
        rel='noopener noreferrer'
        className='businesses__grid__item prispek'
      >
        <img src={Prispek} alt='' className='businesses__grid__item__image' />
      </a>
      <a
        href={Links.spektra}
        target='_blank'
        rel='noopener noreferrer'
        className='businesses__grid__item spektra'
      >
        <img src={Spektra} alt='' className='businesses__grid__item__image' />
      </a>
      <a
        href={Links.prisma}
        target='_blank'
        rel='noopener noreferrer'
        className='businesses__grid__item prisma'
      >
        <img src={Prisma} alt='' className='businesses__grid__item__image' />
      </a>
      <a
        href={Links.liquor}
        target='_blank'
        rel='noopener noreferrer'
        className='businesses__grid__item liquor'
      >
        <img src={Liquor} alt='' className='businesses__grid__item__image' />
      </a>
      {/* <a
        href={Links.ceramic}
        target='_blank'
        rel='noopener noreferrer'
        className='businesses__grid__item ceramic'
      > */}
      <div className='businesses__grid__item ceramic'>
        <img src={Ceramic} alt='' className='businesses__grid__item__image' />
      </div>
      {/* <a
        href={Links.precise}
        target='_blank'
        rel='noopener noreferrer'
        className='businesses__grid__item precise'
      > */}
      <div className='businesses__grid__item precise'>
        <img src={Precise} alt='' className='businesses__grid__item__image' />
      </div>
      <a
        href={Links.surgeon}
        target='_blank'
        rel='noopener noreferrer'
        className='businesses__grid__item surgeon'
      >
        <img src={Surgeon} alt='' className='businesses__grid__item__image' />
      </a>
      <a
        href={Links.rj}
        target='_blank'
        rel='noopener noreferrer'
        className='businesses__grid__item rj'
      >
        <img src={RJ} alt='' className='businesses__grid__item__image' />
      </a>
    </div>
  </div>
);

export default Businesses;
