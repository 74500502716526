import React from 'react';
import Links from '../../Config/Constants/Links';
import { LinkListItem } from './LinkListItem';

const LinksList = () => (
  <div className='footer__links__list'>
    <LinkListItem
      title='Discover'
      list={[
        { to: 'home', label: 'Home' },
        { to: 'about', label: 'About' },
        { to: 'businesses', label: 'Businesses' },
        { to: 'centre management', label: 'Centre Management' }
      ]}
      link
    />
    <LinkListItem
      title='Our Businesses'
      list={[
        { to: Links.prispek, label: 'Prispek Consulting' },
        { to: Links.spektra, label: 'Spektra Brokers' },
        { to: Links.prisma, label: 'Prisma Accountants' },
        { to: Links.liquor, label: 'Liquor City' },
        { to: Links.precise, label: 'Precise Dental Laboratory' },
        { to: Links.ceramic, label: 'Ceramic Design' },
        { to: Links.rj, label: 'The Modern Dentist' },
        { to: Links.surgeon, label: 'Dr Ronel Podde' }
      ]}
    />
  </div>
);

export default LinksList;
