import React from 'react';

import '../Assets/sass/landing.scss';
import Footer from '../Components/Footer/Footer';
import About from '../Components/Landing/About';
import Businesses from '../Components/Landing/Businesses';
import Centre from '../Components/Landing/Centre';
import Header from '../Components/Landing/Header';
import Navbar from '../Components/Navbar';
import { useMyRefs } from '../Config/Context/RefContext';

const LandingPage = () => {
  const { aboutRef, businessesRef, centreRef, topRef } = useMyRefs();
  return (
    <section ref={topRef}>
      <Header />
      <Navbar>
        <section ref={aboutRef} id='about'>
          <About />
        </section>
        <section ref={businessesRef} id='businesses'>
          <Businesses />
        </section>
        <section ref={centreRef} id='centre-management'>
          <Centre />
        </section>
      </Navbar>
      <Footer />
    </section>
  );
};

export default LandingPage;
