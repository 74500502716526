import Carousel from 'react-material-ui-carousel';
import Header01 from '../../Assets/images/Header01.jpg';
import Header02 from '../../Assets/images/Header02.jpg';
import Header03 from '../../Assets/images/Header03.jpg';
import Header04 from '../../Assets/images/Header04.jpg';

const carousel: Array<string> = [Header01, Header02, Header03, Header04];

const Header = () => (
  <Carousel
    className='header'
    indicators={false}
    interval={5000}
    animation='fade'
    stopAutoPlayOnHover={false}
  >
    {carousel.map((slide, index) => (
      <div key={index} className='header__slide'>
        <img src={slide} alt='slide' className='header__slide__image' />
      </div>
    ))}
  </Carousel>
);
export default Header;
