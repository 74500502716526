import { useMyRefs } from '../../Config/Context/RefContext';

export const LinkListItem = ({
  title,
  list,
  link
}: {
  title: string;
  list: Array<{ label: string; to: string }>;
  link?: boolean;
}) => {
  const { aboutRef, businessesRef, centreRef, topRef } = useMyRefs();

  const pageScrollHandler = (to: any) => {
    switch (to) {
      case 'about':
        aboutRef.current?.scrollIntoView();
        break;
      case 'businesses':
        businessesRef.current?.scrollIntoView();
        break;
      case 'centre management':
        centreRef.current?.scrollIntoView();
        break;
      default:
        topRef.current?.scrollIntoView();
        break;
    }
  };

  return (
    <ul className='footer__links__list__sublist'>
      <h4 className='footer__links__list__sublist__title'>{title}</h4>
      {link
        ? list.map(item => (
            <li
              key={item.to}
              onClick={() => pageScrollHandler(item.to)}
              className='footer__links__list__sublist__link'
            >
              {item.label}
            </li>
          ))
        : list.map(item => (
            <a
              key={item.label}
              href={item.to}
              target='_blank'
              rel='noopener noreferrer'
              className='footer__links__list__sublist__link'
            >
              <li>{item.label}</li>
            </a>
          ))}
    </ul>
  );
};
