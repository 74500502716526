import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Drawer,
  Paper,
  Button,
  Grid,
  makeStyles,
  createStyles,
  CircularProgress
} from '@material-ui/core';
import { Close, Send } from '@material-ui/icons';

const useDrawerStyle = makeStyles(() =>
  createStyles({
    form: { maxWidth: '40rem', width: '100%' },
    paper: {
      width: '100%',
      padding: '1.5rem 3vw 3rem',
      maxHeight: '100vh',
      heihgt: 'auto',
      display: 'flex',
      flexDirection: 'column',
      placeContent: 'center',
      placeItems: 'center',
      background: '#eee'
    },
    title: { fontSize: '3.5rem', marginBottom: '1.5rem', textAlign: 'center' }
  })
);

interface ContactDrawerProps {
  openContactDrawer: boolean;
  openContactDrawerHandler: () => void;
}

const ContactDrawer: React.FC<ContactDrawerProps> = ({
  openContactDrawer,
  openContactDrawerHandler
}) => {
  const alert = useAlert();
  const classes = useDrawerStyle();
  const [loading, setLoading] = useState(false);

  interface contactValuesProps {
    Name: string;
    Email: string;
    Phone: string;
    Message: string;
  }

  const contactValues: contactValuesProps = {
    Name: '',
    Email: '',
    Phone: '',
    Message: ''
  };

  const contactValidation = Yup.object({
    Name: Yup.string().required('Required'),
    Email: Yup.string().email('Invalid Email').required('Required'),
    Message: Yup.string().required('Required')
  });

  const contact = useCallback(
    (values: contactValuesProps, { resetForm }: any) => {
      console.log('Values: ', values);
      setLoading(true);
      axios
        .post(`https://service.kruin.co.za/utQuery`, values)
        // .post(`http://localhost:5000/utQuery`, values)
        .then(res => {
          if (res.data.result === 'success') {
            alert.success('Message Sent!');
            setLoading(false);
            resetForm();
            openContactDrawerHandler();
          } else {
            alert.error(res.data.frontEndMessage);
            setLoading(false);
            resetForm();
          }
        })
        .catch(err => console.log(err));
    },
    [alert, openContactDrawerHandler]
  );

  return (
    <Drawer
      anchor='bottom'
      open={openContactDrawer}
      onClose={openContactDrawerHandler}
      style={{ zIndex: 10 }}
    >
      <Paper className={classes.paper}>
        <Formik
          initialValues={contactValues}
          validationSchema={contactValidation}
          onSubmit={contact}
        >
          {({ values }) => (
            <Form className={classes.form}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h1 style={{ fontWeight: 500, color: '#555' }}>
                    Send us a message:
                  </h1>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name='Name'
                    label='Name:'
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name='Email'
                    type='email'
                    label='Email:'
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name='Phone'
                    label='Phone:'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name='Message'
                    label='Messages:'
                    required
                    rows={3}
                    multiline
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                    color='primary'
                    startIcon={
                      loading ? (
                        <CircularProgress color='secondary' size={20} />
                      ) : (
                        <Send />
                      )
                    }
                  >
                    {loading ? 'Sending' : 'Send'}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    type='button'
                    variant='outlined'
                    color='secondary'
                    startIcon={<Close />}
                    onClick={openContactDrawerHandler}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Drawer>
  );
};
export default React.memo(ContactDrawer);
