import React, { useCallback, useState } from 'react';
import {  Mail, Room } from '@material-ui/icons';
import Links from '../../Config/Constants/Links';
import ContactDrawer from '../Contact/ContactDrawer';
import { Button } from '@material-ui/core';

const Contact: React.FC = () => {
  const [openContactDrawer, setOpenContactDrawer] = useState(false);
  const openContactDrawerHandler = useCallback(() => {
    setOpenContactDrawer(prevOpenContactDrawer => !prevOpenContactDrawer);
  }, []);
  return (
    <div className='footer__links__contact'>
      <div className='footer__links__contact__item'>
        <h4 className='footer__links__contact__item__title'>
          Physical Address
        </h4>
        <a
          href={Links.map}
          target='_blank'
          rel='noopener noreferrer'
          className='footer__links__contact__item__link'
        >
          <span>Kruin Business Centre</span>
          <span>406 Braam Pretorius Street</span>
          <span>Magalieskruin</span>
          <span>0159</span>
          <Button startIcon={<Room />}>open in maps</Button>
        </a>
      </div>
      <div className='footer__links__contact__item'>
        <h4 className='footer__links__contact__item__title'>Email</h4>
        <p
          className='footer__links__contact__item__link'
          onClick={openContactDrawerHandler}
        >
          info@kruin.co.za
          <Button startIcon={<Mail />}>Email us</Button>
        </p>
        <ContactDrawer
          openContactDrawer={openContactDrawer}
          openContactDrawerHandler={openContactDrawerHandler}
        />
      </div>
    </div>
  );
};

export default Contact;
