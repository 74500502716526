import React from 'react';
import AlertTemplate from 'react-alert-template-basic';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Theme from './Config/Constants/Theme';
import RefContextProvider from './Config/Context/RefContext';
import LandingPage from './Pages/LandingPage';

const App: React.FC = () => {
  const muiTheme = createMuiTheme({
    palette: {
      primary: { main: Theme.primary },
      secondary: { main: Theme.secondary }
    }
  });
  const alertOptions = {
    position: positions.BOTTOM_CENTER,
    timeout: 3000,
    offset: '30px',
    transition: transitions.SCALE,
    containerStyle: {
      zIndex: 100
    }
  };

  return (
    <RefContextProvider>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <ThemeProvider theme={muiTheme}>
          <LandingPage />
        </ThemeProvider>
      </AlertProvider>
    </RefContextProvider>
  );
};

export default App;
