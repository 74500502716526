import React, { ReactNode, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// import LOGO from '../Assets/images/LOGO.png';
import LOGO from '../Assets/images/LogoBlou.png';
import { useWindowDimensions } from '../Config/Events/WindowDimensions';
import NavLink, { NavLinkProps } from './NavLink';
import { useMyRefs } from '../Config/Context/RefContext';

interface NavbarProps {
  children?: ReactNode;
}

const Navbar = ({ children }: NavbarProps) => {
  const { topRef, aboutRef, businessesRef, centreRef } = useMyRefs();
  const { width } = useWindowDimensions();
  const [offset, setOffset] = useState<number>(0);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [activeLink, setActiveLink] = useState<
    'Home' | 'About' | 'Businesses' | 'Centre Management'
  >('Home');
  //? Links
  const links: Array<NavLinkProps> = [
    {
      link: 'Home',
      page: activeLink === 'Home' ? true : false
    },
    {
      link: 'About',
      page: activeLink === 'About' ? true : false
    },
    {
      link: 'Businesses',
      page: activeLink === 'Businesses' ? true : false
    },
    {
      link: 'Centre Management',
      page: activeLink === 'Centre Management' ? true : false
    }
  ];

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
      if (window.pageYOffset > centreRef.current?.offsetTop - 150) {
        setActiveLink('Centre Management');
      } else if (window.pageYOffset > businessesRef.current?.offsetTop - 150) {
        setActiveLink('Businesses');
      } else if (window.pageYOffset > aboutRef.current?.offsetTop - 150) {
        setActiveLink('About');
      } else {
        setActiveLink('Home');
      }
    };
    window.onload = () => setOffset(window.pageYOffset);
  }, [aboutRef, businessesRef, centreRef]);
  //? Mui Styles
  const useStyles = makeStyles(theme => ({
    container: {
      padding: '2rem 5vw ',
      minHeight: '100vh',
      background: '#eee',
      zIndex: 10
    },
    drawer: {
      width: 240,
      flexShrink: 0,
      display: 'flex'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
      color: 'white'
    },
    drawerLinks: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-start',
      padding: '1rem 0',
      height: '100%'
    },
    drawerPaper: {
      width: 240,
      display: 'flex',
      background: '#eee'
    },
    link: {
      fontWeight: 700,
      fontSize: '1rem',
      color: 'white',
      textAlign: 'left'
    },
    logo: { width: '10rem', display: 'table' },
    navbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0.5rem',
      alignItems: 'center',
      background:
        // eslint-disable-next-line no-restricted-globals
        offset > screen.height - 200
          ? 'linear-gradient(to bottom right, rgb(150, 150, 150), rgb(150, 150, 150))'
          : 'linear-gradient(to bottom, rgba(6,6,6,0.8), rgba(6,6,6,0.1))',
      zIndex: 10
    },
    toolbar: theme.mixins.toolbar
  }));
  const classes = useStyles();
  //? Handlers
  const drawerToggleHandler = () => {
    setMobileOpen(!mobileOpen);
  };

  const pageScrollHandler = (
    link: 'Home' | 'About' | 'Businesses' | 'Centre Management'
  ) => {
    width < 858 && setMobileOpen(false);
    switch (link) {
      case 'About':
        aboutRef.current?.scrollIntoView();
        break;
      case 'Businesses':
        businessesRef.current?.scrollIntoView();
        break;
      case 'Centre Management':
        centreRef.current?.scrollIntoView();
        break;
      default:
        topRef.current?.scrollIntoView();
        break;
    }
  };

  return (
    <>
      <AppBar
        color='transparent'
        position='fixed'
        elevation={0}
        className={classes.navbar}
      >
        <Toolbar>
          <img
            src={LOGO}
            alt='Kruin Logo'
            className={classes.logo}
            onClick={() => pageScrollHandler('Home')}
          />
        </Toolbar>
        <Toolbar>
          {width > 858
            ? links.map(link => (
                <NavLink
                  key={link.link}
                  link={link.link}
                  page={link.page}
                  onClick={() => pageScrollHandler(link.link)}
                  className={classes.link}
                  style={{ margin: '0 1rem' }}
                />
              ))
            : !mobileOpen && (
                <IconButton
                  color='primary'
                  aria-label='open drawer'
                  edge='end'
                  onClick={drawerToggleHandler}
                >
                  <MenuIcon />
                </IconButton>
              )}
        </Toolbar>
        <Drawer
          className={classes.drawer}
          variant='temporary'
          anchor='right'
          open={mobileOpen}
          onClose={drawerToggleHandler}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={drawerToggleHandler}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Divider />
          <div className={classes.drawerLinks}>
            {links.map(link => (
              <NavLink
                key={link.link}
                link={link.link}
                page={link.page}
                onClick={() => pageScrollHandler(link.link)}
                className={classes.link}
                style={{ margin: '1rem 0' }}
                drawer
                fullWidth
              />
            ))}
          </div>
        </Drawer>
      </AppBar>
      <div className={classes.container}>{children}</div>
    </>
  );
};

export default Navbar;
