import React from 'react';

import './footer.scss';
import Contact from './Contact';
import LinksList from './LinksList';

const Footer = () => (
  <div className='footer'>
    <div className='footer__links'>
      <Contact />
      <LinksList />
    </div>
    <p className='footer__copyright'>
      &copy; {new Date().getFullYear()} Kruin Business Centre | develop by
      Vermelion (Pty) Ltd
    </p>
  </div>
);

export default Footer;
