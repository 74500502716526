import React, { useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import { Mail, Room } from '@material-ui/icons';
import Shops from '../../Assets/images/Shops.jpg';
import Links from '../../Config/Constants/Links';
import ContactDrawer from '../Contact/ContactDrawer';

const Centre: React.FC = () => {
  const [openContactDrawer, setOpenContactDrawer] = useState(false);
  const openContactDrawerHandler = useCallback(() => {
    setOpenContactDrawer(prevOpenContactDrawer => !prevOpenContactDrawer);
  }, []);
  return (
    <div className='centre--container'>
      <h1 className='centre--container__title'>Centre Management</h1>
      <div className='centre'>
        <img src={Shops} alt='centre mangement' className='centre__image' />
        <div className='centre__content'>
          <div className='centre__content__item'>
            <h2 className='centre__content__item__title'>
              Trustees for the Body Corporate:
            </h2>
            <p className='centre__content__item__link'>
              Jacques Podde (Chairman)
            </p>
            <p className='centre__content__item__link'>Albert da Costa</p>
            <p className='centre__content__item__link'>Stephan Greyling</p>
            <p className='centre__content__item__link'>Dieter Haarde</p>
            <p className='centre__content__item__link'>Gerhard Labuschagne</p>
            <p className='centre__content__item__link'>Lizelle Labuschagne</p>
            <p className='centre__content__item__link'>John Muire</p>
            <p className='centre__content__item__link'>Christo Viljoen</p>
          </div>
          <div className='centre__content__item'>
            <h2 className='centre__content__item__title'>Physical Address:</h2>
            <a
              href={Links.map}
              target='_blank'
              rel='noopener noreferrer'
              className='centre__content__item__link'
            >
              <span className='centre__content__item__link__span'>
                Kruin Business Centre
              </span>
              <span className='centre__content__item__link__span'>
                406 Braam Pretorius Street
              </span>
              <span className='centre__content__item__link__span'>
                Magalieskruin
              </span>
              <span className='centre__content__item__link__span'>0159</span>
              <Button className='centre__buttons' startIcon={<Room />}>
                open in maps
              </Button>
            </a>
          </div>
          <div className='centre__content__item'>
            <h2 className='centre__content__item__title'>EMAIL:</h2>
            <p
              className='centre__content__item__link'
              onClick={openContactDrawerHandler}
            >
              info@kruin.co.za
              <Button className='centre__buttons' startIcon={<Mail />}>
                Email us
              </Button>
            </p>
            <ContactDrawer
              openContactDrawer={openContactDrawer}
              openContactDrawerHandler={openContactDrawerHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Centre;
